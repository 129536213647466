import React from 'react';
import './RecipeIngredients.css';
import IngredientBlock from './IngredientBlock';
import RecipeCheckout from './RecipeCheckout.tsx';

const RecipeIngredients = ({ recipe }) => {

    return (
        <div className="container">
            <RecipeCheckout/>
            <h3>Shopping List:</h3>
            <ul className="ingredient-list">
                {recipe.ingredients.map((ingredient) => (
                    <IngredientBlock
                        key={ingredient.id}
                        ingredient={ingredient}
                        recipeId={recipe.id}
                    />
                ))}
            </ul>
        </div>
    );
};

export default RecipeIngredients;