import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import RecipeDescription from './components/RecipeDescription';
import RecipeIngredients from './components/RecipeIngredients';
import API_URL from './config';
import { FaSyncAlt } from 'react-icons/fa';
import './RecipeView.css';

const RecipeView = () => {
    const { recipeId } = useParams();
    const [recipe, setRecipe] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [statusIndicator, setStatusIndicator] = useState('');

    const fetchTaskStatus = (taskID) => {
        return axios.get(`${API_URL}/task_status/${taskID}`);
    };

    const handleRecompute = useCallback(() => {
        if (recipe && recipe.source_url) {
            setLoading(true);
            axios.post(`${API_URL}/reprocess_recipe/`, { recipe_id: recipeId, url: recipe.source_url })
                .then(response => {
                    if (response.data && response.data.task_id) {
                        const pollInterval = setInterval(() => {
                            fetchTaskStatus(response.data.task_id)
                                .then(statusResponse => {
                                    const status = statusResponse.data.status;
                                    setStatusIndicator(status);
                                    if (status === "completed") {
                                        clearInterval(pollInterval);
                                        setLoading(false);
                                        axios.get(`${API_URL}/recipe/${recipeId}`)
                                            .then(response => {
                                                setRecipe(response.data);
                                            })
                                            .catch(err => {
                                                setError(err.message);
                                                console.error(err);
                                            });
                                    }
                                })
                                .catch(error => {
                                    console.error("Error fetching task status:", error);
                                    clearInterval(pollInterval);
                                    setLoading(false);
                                });
                        }, 500);
                    }
                })
                .catch(error => {
                    console.error("Error recomputing recipe:", error);
                    setLoading(false);
                });
        }
    }, [recipe, recipeId]);

    useEffect(() => {
        axios.get(`${API_URL}/recipe/${recipeId}`)
            .then(response => {
                for (const ingredient of response.data.ingredients) {
                    if (ingredient.normalized_unit === '') {
                        ingredient.normalized_unit = 'count';
                    }
                }
                setRecipe(response.data);
            })
            .catch(err => {
                setError(err.message);
                console.error(err);
            });
    }, [recipeId]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!recipe) {
        return <div>Loading...</div>;
    }

    return (
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <div style={{ display: 'flex', flex: 1 }}>
                <div style={{ width: '50%', height: '100%', boxSizing: 'border-box' }}>
                    <RecipeDescription recipe={recipe} />
                </div>
                <div style={{ width: '50%', height: '100%', borderLeft: '1px solid #ccc', padding: '10px', boxSizing: 'border-box' }}>
                    <RecipeIngredients recipe={recipe} />
                </div>
            </div>
            <div>
                <FaSyncAlt className={`${loading ? 'icon-spin' : ''}`} style={{ fontSize: '24px', cursor: 'pointer', color: '#ccc'}} onClick={handleRecompute} />
            </div>
            {loading && (
                <div className="loading-container">
                    <div className="spinner"></div>
                    <p>Recomputing...{statusIndicator}</p>
                </div>
            )}
      </div>
  );
};

export default RecipeView;