import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import RecipeSearchResult from './RecipeSearchResult';
import './SearchBar.css';
import API_URL from '../config';

const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...args);
        }, wait);
    };
};

const SearchBar = () => {
    const [query, setQuery] = useState('');
    const [recipes, setRecipes] = useState([]);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [statusIndicator, setStatusIndicator] = useState('');
    const limit = 10;

    const fetchTaskStatus = (taskID) => {
        return axios.get(`${API_URL}/task_status/${taskID}`);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearch = useCallback(debounce(() => {
        if (/^(http|https):\/\/[^ "]+$/.test(query)) {
            setLoading(true);
            const cancelTokenSource = axios.CancelToken.source();
            axios.post(`${API_URL}/add_recipe/`, { url: query }, { cancelToken: cancelTokenSource.token })
                .then(response => {
                    if (response.data && response.data.task_id) {
                        const pollInterval = setInterval(() => {
                            fetchTaskStatus(response.data.task_id)
                                .then(statusResponse => {
                                    const status = statusResponse.data.status;
                                    setStatusIndicator(status);
                                    if (status === "completed") {
                                        clearInterval(pollInterval);
                                        setLoading(false);
                                        window.location.href = `/recipe-view/${statusResponse.data.recipe_id}`;
                                    }
                                })
                                .catch(error => {
                                    console.error("Error fetching task status:", error);
                                    clearInterval(pollInterval);
                                    setLoading(false);
                                });
                        }, 500);
                    }
                })
                .catch(error => {
                    if (!axios.isCancel(error)) {
                        console.error("Error adding recipe:", error);
                        setLoading(false);
                    }
                });

            return () => cancelTokenSource.cancel();
        } else {
            axios.get(`${API_URL}/search/?query=${query}&skip=${skip}&limit=${limit + 1}`)
                .then(response => {
                    setRecipes(response.data);
                })
                .catch(error => {
                    console.error("Error fetching recipes:", error);
                });
        }
    }, 300), [query, skip]);

    useEffect(() => {
        handleSearch();
    }, [query, skip, handleSearch]);

    return (
        <div className="search-bar-container">
            <div className="search-input-wrapper">
                <input 
                    className="search-input"
                    value={query} 
                    onChange={e => {
                        setQuery(e.target.value);
                        setSkip(0);
                    }}
                    placeholder="Search for a recipe or enter a recipe URL" 
                    disabled={loading}
                />
            </div>
            {loading ? (
                (
                    <div className="loading-container">
                        <div className="spinner">
                        <div className="ring"></div>
                        <img src={'./logo512.png'} alt="Mascot stirring ingredients" className="mascot-image" />
                        </div>
                        <p>Loading... {statusIndicator}</p>
                    </div>
                )
            ) : (
                recipes.slice(0, limit).map(recipe => (
                    <RecipeSearchResult key={recipe.id} recipe={recipe} />
                ))
            )}
        
            <div className="nav-buttons">
                <button className="nav-button" onClick={() => setSkip(prev => prev - limit)} disabled={skip === 0 || loading}>Previous</button>
                <button className="nav-button" onClick={() => setSkip(prev => prev + limit)} disabled={recipes.length <= limit || loading}>Next</button>
            </div>
        </div>
    );
};

export default SearchBar;