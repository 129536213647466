import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import API_URL from '../config';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styles from './TopProduct.module.css';
import { ProductOptionsList } from './ProductOptionsList';
import { getProductPriceInfo, getProductQuantity, formatQuantity} from '../utils/PriceCalculation';
import { useDispatch } from 'react-redux';
import { selectProduct } from '../state/mealPlanSlice.ts';


const IngredientBlock = ({ ingredient }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedIngredient, setSelectedIngredient] = useState({});
    const [ingredientOptions, setIngredientOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();

    const fetchClosestIngredients = useCallback(async () => {
        try {
            const response = await axios.get(`${API_URL}/recipe-ingredient/${ingredient.id}/closest-ingredients`);
            if (response.data) {
                setIngredientOptions(response.data.ingredients.map(ingredient => ({
                    ingredient_id: ingredient.ingredient_id,
                    ingredient_name: ingredient.ingredient_name,
                })));
            }
        } catch (err) {
            console.error('Failed to fetch closest ingredients:', err);
        }
    }, [ingredient.id]);

    const handleProductSelection = useCallback((product) => {
        setSelectedProduct(product);
        dispatch(selectProduct({
            recipeId: ingredient.recipe_id,
            ingredientId: ingredient.id,
            product,
            ingredient
        }));
    }, [dispatch, ingredient]);

    useEffect(() => {
        const fetchTopProduct = async (ingredientId) => {
            try {
                const response = await axios.get(`${API_URL}/ingredient/${ingredientId}/top-product`);
                if (response.data) {
                    handleProductSelection(response.data.top_product);
                }
            } catch (err) {
                console.error('Failed to fetch top product:', err);
            }
        };

        setSelectedIngredient(ingredient);
        if (ingredient && ingredient.ingredient_id) {
            fetchTopProduct(ingredient.ingredient_id);
        }
    }, [ingredient, handleProductSelection]);

    useEffect(() => {
        fetchClosestIngredients();
    }, [fetchClosestIngredients]);

    useEffect(() => {
        if (selectedIngredient && selectedIngredient.ingredient_id) {
            fetchProductOptions(selectedIngredient.ingredient_id);
        }
    }, [selectedIngredient, ingredientOptions]);

    const fetchProductOptions = async (ingredientId) => {
        try {
            const response = await axios.get(
                `${API_URL}/product-options/${ingredientId}?top_n=10`
            );
            if (response.data) {
                setProductOptions(response.data.products);
            }
        } catch (err) {
            console.error('Failed to fetch product options:', err);
        }
    };

    const handleExpandToggle = () => {
        setExpanded(prevState => !prevState);
        if (!expanded) {
            fetchProductOptions(selectedIngredient.ingredient_id);
        }
    };

    const handleDropdownChange = (e) => {
        const selectedName = e.target.value;
        const selectedOption = ingredientOptions.find(option => option.ingredient_name === selectedName);
        setSelectedIngredient(selectedOption);
    };

    const handleDropdownClick = () => {
        if (!ingredientOptions.length) {
            fetchClosestIngredients();
        }
    };

    const ProductImage = ({ imageUrl, altText }) => (
        <div style={{ width: '50px', height: '50px', overflow: 'hidden', borderRadius: '50%' }}>
            <img src={imageUrl} alt={altText} style={{ width: '100%', height: '100%' }} />
        </div>
    );

    const IngredientSelect = ({ selectedIngredient, ingredientOptions, handleDropdownChange, handleDropdownClick }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div style={{ flex: 1, minWidth: 0 }}>
            <select
                value={selectedIngredient.ingredient_name || ''}
                onChange={handleDropdownChange}
                onClick={handleDropdownClick}
                style={{ 
                    fontSize: '16px', 
                    border: '1px solid lightgray', 
                    borderRadius: '5px', 
                    padding: '5px',
                    width: '100%',
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {ingredientOptions ? (
                    ingredientOptions.map(option => (
                        <option key={option.ingredient_id} value={option.ingredient_name}>
                            {option.ingredient_name}
                        </option>
                    ))
                ) : (
                    <option value="">{selectedIngredient.ingredient_name}</option>
                )}
            </select>
            </div>
        </div>
    );

    const ProductSelectionHeader = ({ quantityNeeded, topProduct, handleExpandToggle, expanded }) => {
        return (
            <div style={{ fontSize: '16px', display: 'flex', alignItems: 'center' }}>
                <button onClick={handleExpandToggle} style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '0', marginRight: '5px' }}>
                    {expanded ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
                </button>
                {quantityNeeded && <span style={{ fontWeight: 'bold', paddingRight: '5px' }}>{quantityNeeded}</span>}
                <span>{topProduct.title}</span>
            </div>
        );
    };

    const priceInfo = getProductPriceInfo(selectedProduct, ingredient);
    const quantityNeeded = getProductQuantity(selectedProduct, ingredient);
    const priceMultiplier = quantityNeeded ? quantityNeeded : 1;

    return (
        <li style={{ marginBottom: '15px', padding: '10px', border: '1px solid lightgray', borderRadius: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: '10px', flex: 1 }}>
                    {selectedProduct && selectedProduct.image_url && <ProductImage imageUrl={selectedProduct.image_url} altText={selectedProduct.title} />}
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px', flexWrap: 'wrap' }}>
                            {ingredient.raw_text && <span>{ingredient.raw_text} | </span>}
                            {ingredient.quantity && <span>{formatQuantity(ingredient.quantity)} </span>}
                            {ingredient.unit && <span>{ingredient.unit} of </span>}
                            {ingredient.ingredient_name && (
                                <IngredientSelect
                                    selectedIngredient={selectedIngredient}
                                    ingredientOptions={ingredientOptions}
                                    handleDropdownChange={handleDropdownChange}
                                    handleDropdownClick={() => handleDropdownClick()}
                                />
                            )}
                            {ingredient.preparation && <span> ({ingredient.preparation})</span>}
                        </div>
                        {selectedProduct && selectedProduct.title && <ProductSelectionHeader topProduct={selectedProduct} quantityNeeded={quantityNeeded} handleExpandToggle={handleExpandToggle} expanded={expanded} />}
                    </div>
                </div>
    
                {selectedProduct && selectedProduct.price && (
                    <div className={styles.pricePill} style={{ maxWidth: 'calc(100% - 10px)', overflow: 'auto' }}>
                        <div className={styles.price}>
                            ${ (Math.round(selectedProduct.price * 100 * priceMultiplier) / 100).toFixed(2) }
                        </div>
                        {priceInfo && (
                            <div className={styles.priceDetails}>
                                <div>Used: ${ priceInfo.priceOfQuantityUsed.toFixed(2) }</div>
                                <div>Leftover ${ priceInfo.priceOfQuantityRemaining.toFixed(2) }</div>
                            </div>
                        )}
                    </div>

                )}
            </div>
            {expanded && <ProductOptionsList recipeIngredient={ingredient} selectedProduct={selectedProduct} selectedIngredient={selectedIngredient} productOptions={productOptions} handleProductSelection={handleProductSelection} />}
        </li>
    );
}

export default IngredientBlock;