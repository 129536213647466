import { configureStore } from '@reduxjs/toolkit';
import mealPlanReducer from './mealPlanSlice.ts';

type RootState = {
  mealPlan: ReturnType<typeof mealPlanReducer>;
};

const store = configureStore({
  reducer: {
    mealPlan: mealPlanReducer
  }
});

export default store;
export type { RootState };