import React, { useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import './RecipeDescription.css';

const RecipeDescription = ({ recipe }) => {
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheck = (index) => {
        setCheckedItems(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    function cssUrlEncode(url) {
        // Replace characters like spaces and parentheses that might cause problems in CSS
        return url.replace(/[\s()'"]/g, function(char) {
            return '%' + char.charCodeAt(0).toString(16);
        });
    }

    return (
        <div>
            <div className="title-container">
                <h2>
                    {recipe.title} 
                    {recipe.source_url && (
                        <a href={recipe.source_url} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
                            <FaExternalLinkAlt size={16}/>
                        </a>
                    )}
                </h2>
            </div>
            <div 
                className="image-container" 
                style={{ background: `url(${cssUrlEncode(recipe.picture_link)}) no-repeat center center` }}
            ></div>
    
            <div className="metadata">
                <div className="metadata-item">
                    <h4>Preparation Time: {recipe.preparation_minutes} minutes</h4>
                </div>
                <div className="metadata-item">
                    <h4>Cooking Time: {recipe.cooking_minutes} minutes</h4>
                </div>
                <div className="metadata-item">
                    <h4>Servings: {recipe.servings}</h4>
                </div>
            </div>
    
            <div>
                {recipe.instruction_lines && recipe.instruction_lines.map((line, index) => (
                    <div className="instruction-card" key={index}>
                        <input 
                            type="checkbox" 
                            id={`instruction-${index}`} 
                            checked={checkedItems[index] || false}
                            onChange={() => handleCheck(index)}
                        />
                        <label 
                            htmlFor={`instruction-${index}`} 
                            style={{ textDecoration: checkedItems[index] ? 'line-through' : 'none' }}
                        >
                            {line}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RecipeDescription;