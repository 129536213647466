import React from 'react';
import styles from './TopProduct.module.css';
import { useAuth } from '../AuthContext';
import AddToCartButton from './AddToCartButton';
import OAuthButton from './OAuthButton';
import { getProductPriceInfo, getProductQuantity } from '../utils/PriceCalculation';

const RecipePriceDisplay = ({ recipe }) => {
    const { authToken } = useAuth();

    // Function to calculate price for a single ingredient
    const calculateIngredientPrice = (ingredient) => {
        const quantityNeeded = getProductQuantity(ingredient.selectedProduct, ingredient);
        const priceMultiplier = quantityNeeded ? quantityNeeded : 1;
        return ingredient.selectedProduct ? ingredient.selectedProduct.price * priceMultiplier : 0;
    };

    // Function to calculate price info for a single ingredient
    const calculateIngredientPriceInfo = (ingredient) => {
        const priceInfo = getProductPriceInfo(ingredient.selectedProduct, ingredient);
        return priceInfo ? {
            priceOfQuantityUsed: priceInfo.priceOfQuantityUsed,
            priceOfQuantityRemaining: priceInfo.priceOfQuantityRemaining
        } : {
            priceOfQuantityUsed: 0,
            priceOfQuantityRemaining: 0
        };
    };

    const price = recipe.ingredients.reduce((total, ingredient) => {
        return total + calculateIngredientPrice(ingredient);
    }, 0);

    const priceInfo = recipe.ingredients.reduce((total, ingredient) => {
        const ingredientPriceInfo = calculateIngredientPriceInfo(ingredient);
        return {
            priceOfQuantityUsed: total.priceOfQuantityUsed + ingredientPriceInfo.priceOfQuantityUsed,
            priceOfQuantityRemaining: total.priceOfQuantityRemaining + ingredientPriceInfo.priceOfQuantityRemaining
        };
    }, { priceOfQuantityUsed: 0, priceOfQuantityRemaining: 0 });

    return (
        <div className={styles.pricePill}>
            <div className={styles.price}>
                ${ price.toFixed(2) }
            </div>
            <div className={styles.priceDetails}>
                <div>Used: ${ priceInfo.priceOfQuantityUsed.toFixed(2) }</div>
                <div>Leftover: ${ priceInfo.priceOfQuantityRemaining.toFixed(2) }</div>
            </div>
            {recipe && (
                authToken ? (
                    <AddToCartButton recipe={recipe} modality="PICKUP" />
                ) : (
                    <span><OAuthButton/></span>
                )
            )}
        </div>
    );
};

export default RecipePriceDisplay;