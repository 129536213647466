import React from 'react';
import { Link } from 'react-router-dom';
import './RecipeSearchResult.css';

const extractDomain = (url) => {
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (error) {
        console.error("Invalid URL:", error);
        return url;
    }
};

const RecipeSearchResult = ({ recipe }) => {
    return (
        <div className="card-wrapper" style={{ width: '100%' }}>
            <Link to={`/recipe-view/${recipe.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="recipe-title">
                    {recipe.title}
                </div>
                <div className="recipe-content">
                    <div className="recipe-image-wrapper">
                        <img src={recipe.picture_link} alt={`${recipe.title}`} className="recipe-image" />
                    </div>
                    <div className="recipe-metadata">
                        <p>Servings: {recipe.servings}</p>
                        <p>Source: {extractDomain(recipe.source_url)}</p>
                        <p>Preparation: {recipe.preparation_minutes} mins</p>
                        <p>Difficulty: {recipe.difficulty_level}</p>
                        <p>Cooking: {recipe.cooking_minutes} mins</p>
                        <p>Cuisine: {recipe.cuisine_type}</p>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default RecipeSearchResult;