import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Make sure to import the CSS file

const Header = () => (
  <header className="header">
    <div className="logo-container">
      <img src="/logo512.png" alt="Kitchen Copilot Logo" className="logo" />
      <h1 className="app-name">Kitchen Copilot</h1>
    </div>
    <nav className="navigation">
      <Link to="/" className="header-link">Recipe Search</Link>
      <Link to="/meal-plan" className="header-link">Meal Plan</Link>
    </nav>
  </header>
);

export default Header;