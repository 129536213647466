export const getUnitType = (ingredientUnit, productUnits) => {
    /*
    Given a list of available product units and an ingredient unit, return the relevant unit type
    */
    const matchingUnit = productUnits.find(unit => unit.unit === ingredientUnit);
    if (matchingUnit) {
        return { quantity: matchingUnit.quantity, unit: matchingUnit.unit };
    } else {
        if(ingredientUnit === 'fl oz') {
            const matchingUnit = productUnits.find(unit => unit.unit === 'oz');
            if (matchingUnit) {
                return { quantity: matchingUnit.quantity, unit: matchingUnit.unit };
            }
        }
        if(ingredientUnit === '') {
            const matchingUnit = productUnits.find(unit => unit.unit === 'count');
            if (matchingUnit) {
                return { quantity: matchingUnit.quantity, unit: matchingUnit.unit };
            }
        }
        console.log("No matching unit found:", productUnits, ingredientUnit)
        return {quantity: undefined, unit: undefined};
    }
}

export const getQuantityNeeded = (ingredientQuantity, productQuantity) => {
    if (ingredientQuantity !== undefined && productQuantity !== undefined) {
        return Math.ceil(ingredientQuantity / productQuantity);
    }
    return undefined;
};

export const getAmountLeftOver = (ingredient, productUnit, quantityNeeded) => {
    if (ingredient.normalized_quantity !== undefined && productUnit.quantity !== undefined) {
        const amountLeftOver = (productUnit.quantity * quantityNeeded) - ingredient.normalized_quantity;
        return amountLeftOver;
    }
    return undefined;
};

export const getLeftOverPercentage = (amountLeftOver, productUnit, quantityNeeded) => {
    if (
        typeof amountLeftOver === 'number' &&
        productUnit &&
        typeof productUnit.quantity === 'number' &&
        productUnit.quantity !== 0
    ) {
        return Math.round(amountLeftOver / (productUnit.quantity*quantityNeeded) * 100);
    }
    return null;
};

export const calculateUnitPrice = (price, quantity, unit) => {
    if (price && quantity && quantity > 0) {
        const unitPrice = price / quantity;
        return `$${(unitPrice).toFixed(2)} per ${unit}`;
    }
    return '';
};

export const formatQuantity = (quantity) => {
    if (Number.isInteger(quantity)) {
        return quantity.toString();
    }

    const wholeNumberPart = Math.floor(quantity);
    const fractionalPart = quantity - wholeNumberPart;

    const fractions = [
        { fraction: '⅛', value: 0.125 },
        { fraction: '¼', value: 0.25 },
        { fraction: '⅓', value: 0.3333 },
        { fraction: '⅜', value: 0.375 },
        { fraction: '½', value: 0.5 },
        { fraction: '⅝', value: 0.625 },
        { fraction: '⅔', value: 0.6666 },
        { fraction: '¾', value: 0.75 },
        { fraction: '⅞', value: 0.875 },
    ];

    let minDifference = Math.abs(fractionalPart - fractions[0].value);
    let closestFraction = fractions[0].fraction;

    for (const fraction of fractions) {
        const difference = Math.abs(fractionalPart - fraction.value);
        if (difference < minDifference) {
            minDifference = difference;
            closestFraction = fraction.fraction;
        }
    }

    if (minDifference < 0.01) {
        return wholeNumberPart > 0 ? `${wholeNumberPart} ${closestFraction}` : closestFraction;
    }

    return parseFloat(quantity).toFixed(2);
};

export const getProductQuantity = (product, ingredient) => {
    if (product && ingredient.normalized_quantity && ingredient.normalized_unit) {
        const productUnit = getUnitType(ingredient.normalized_unit, product.units);
        return getQuantityNeeded(ingredient.normalized_quantity, productUnit.quantity);
    }
    return undefined;
};

export const getProductPriceInfo = (product, ingredient) => {
    if (product && product.price) {
        const quantityNeeded = getProductQuantity(product, ingredient);
        const productUnits = getUnitType(ingredient.normalized_unit, product.units);
        if (quantityNeeded !== undefined) {
            const totalPrice = product.price * quantityNeeded;
            const priceOfQuantityUsed = totalPrice * (ingredient.normalized_quantity / (productUnits.quantity  * quantityNeeded));
            const priceOfQuantityRemaining = totalPrice - priceOfQuantityUsed;
            return {
                totalPrice,
                priceOfQuantityUsed,
                priceOfQuantityRemaining
            };
        }
    }
    return undefined;
};