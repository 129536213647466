import React from 'react';
import SearchBar from './components/SearchBar';
import './RecipeSearch.css';

const RecipeSearch = () => (
  <div className="recipe-search-container">
    <div className="search-bar-wrapper">
      <SearchBar />
    </div>
  </div>
);

export default RecipeSearch;