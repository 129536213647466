import React, { useState, useEffect } from 'react';
import axios from 'axios';
import API_URL from '../config';
import styles from './OAuthButton.module.css';

const OAuthButton = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const getAuthCodeUrl = async (currentPath) => {
    const stateValue = encodeURIComponent(currentPath);
    const response = await axios.get(`${API_URL}/auth_code_url?state=${stateValue}`);
    window.location.href = response.data.url;
  };

  const getAccessToken = async (code) => {
    const response = await axios.post(`${API_URL}/get_access_token`, { code });
    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);
  };

  const refreshAccessToken = async () => {
    const response = await axios.post(`${API_URL}/refresh_access_token`, { refresh_token: refreshToken });
    setAccessToken(response.data.access_token);
    setRefreshToken(response.data.refresh_token);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const originalPath = decodeURIComponent(urlParams.get('state'));
    if (code) {
      getAccessToken(code)
        .then(() => {
          if (originalPath) {
            window.location.href = originalPath;
          }
        });
    }
  }, []);

  return (
    <div>
        {!accessToken ? (
            <div className={styles.buttonContainer} onClick={() => getAuthCodeUrl(window.location.pathname)}>
                <img src="/kroger_logo.png" alt="Kroger Logo" className={styles.krogerLogo} />
                Sign In to Order
            </div>
        ) : (
            <div className={styles.accessTokenContainer}>
                <h3>Access Token</h3>
                <p>{accessToken}</p>
                <button className={styles.refreshButton} onClick={refreshAccessToken}>Refresh Access Token</button>
            </div>
        )}
    </div>
);
};

export default OAuthButton;