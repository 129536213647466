import React, { useState } from 'react';
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import axios from 'axios';
import API_URL from '../config';


export const FeedbackButtons = ({ productId, selectedIngredient }) => {
    const [feedbackState, setFeedbackState] = useState({});

    const handleFeedback = async (feedbackValue, productId) => {
        try {
            await axios.post(`${API_URL}/product_ingredient_feedback/`, {
                ingredient_id: selectedIngredient.ingredient_id,
                product_id: productId,
                feedback_value: feedbackValue,
            });
            setFeedbackState(prevState => ({
                ...prevState,
                [productId]: feedbackValue,
            }));
        } catch (err) {
            console.error('Failed to submit feedback:', err);
        }
    };

    return (
        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <button
                onClick={() => handleFeedback('thumbs_up', productId)}
                style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    color: feedbackState[productId] === 'thumbs_up' ? 'green' : 'lightgray',
                }}
            >
                <FaThumbsUp />
            </button>
            <button
                onClick={() => handleFeedback('thumbs_down', productId)}
                style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    color: feedbackState[productId] === 'thumbs_down' ? 'red' : 'lightgray',
                }}
            >
                <FaThumbsDown />
            </button>
        </div>
    )};