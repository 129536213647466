import React, { useCallback, useState } from 'react';
import { useAuth } from '../AuthContext';
import styles from './AddToCartButton.module.css';

const AddToCartButton = ({ recipe, modality }) => {
  const { authToken } = useAuth();
  const [orderPlaced, setOrderPlaced] = useState(false);

  const addToCart = useCallback(async () => {
    if (!authToken) {
        console.log('User is not authenticated');
        return;
    }

    const url = 'https://api.kroger.com/v1/cart/add';
    const body = {
      items: recipe.ingredients.map(ingredient => ({
        quantity: ingredient.selectedProduct.unitsRequired ? ingredient.selectedProduct.unitsRequired : 1,
        upc: ingredient.selectedProduct.id,
        modality
      }))
    };
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `bearer ${authToken}`
    };

    try {
      setOrderPlaced(true);
      const response = await fetch(url, {
        method: 'PUT',
        headers,
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        console.error('Failed to add item to cart:', response.statusText);
        const text = await response.text();
        console.error('Response body:', text);
        return;
      }

      const data = await response.json();
      console.log('Item added to cart:', data);

    } catch (error) {
        console.log(JSON.stringify(body))
        console.error('Failed to add item to cart:', error);
    }
  }, [authToken, recipe, modality]);

  return (
    <button className={styles.button} onClick={addToCart}>
        {orderPlaced ? 'Order Placed ' : 'Add to Cart '}
        {orderPlaced && <span className={styles.checkmark}>&#10004;</span>}
    </button>
  );
};

export default AddToCartButton;