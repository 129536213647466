import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Unit {
  unit: string;
  quantity: number;
}

export interface Product {
  brand: string;
  countryOrigin: string;
  id: string;
  image_url: string;
  pack_size: string;
  price: number;
  title: string;
  selectedUnit: Unit;
  units: Unit[];
  unitsRequired?: number;
}

export interface Ingredient {
  id: number;
  ingredient_id: number;
  ingredient_name: string;
  normalized_quantity: number;
  normalized_unit: string;
  preparation: string;
  quantity: number;
  raw_text: string;
  recipe_id: number;
  recipe_ingredient_name: string;
  source: string;
  unit: string;
  selectedProduct?: Product;
}

export interface Recipe {
  id: string;
  ingredients: Ingredient[];
}

export interface MealPlanState {
  selectedRecipes: Recipe[];
}

const initialState: MealPlanState = {
  selectedRecipes: [],
};

interface SelectProductPayload {
  recipeId: string;
  ingredientId: number;
  product: Product;
  ingredient: Ingredient;
}

const mealPlanSlice = createSlice({
  name: 'mealPlan',
  initialState,
  reducers: {
    selectProduct(state, action: PayloadAction<SelectProductPayload>) {
      const { recipeId, ingredientId, product, ingredient } = action.payload;
      let recipe = state.selectedRecipes.find(r => r.id === recipeId);
      if (!recipe) {
        recipe = { id: recipeId, ingredients: [] };
        state.selectedRecipes.push(recipe);
      }
      let ingredientItem = recipe.ingredients.find(i => i.id === ingredientId);
      if (!ingredientItem) {
        ingredientItem = { ...ingredient, selectedProduct: undefined };  // Copy properties from the ingredient payload
        recipe.ingredients.push(ingredientItem);
      }

      // Calculate and set the units required on the product object
      const unitsRequired = ingredient.quantity / product.unitQuantity;
      const updatedProduct = { ...product, unitsRequired };
      
      // Update the selected product on the ingredient item
      ingredientItem.selectedProduct = updatedProduct;
    },
  }
});

export const { selectProduct } = mealPlanSlice.actions;
export default mealPlanSlice.reducer;