import { calculateUnitPrice, getUnitType, getQuantityNeeded, getAmountLeftOver, getLeftOverPercentage } from '../utils/PriceCalculation';
import styles from './TopProduct.module.css';
import { FeedbackButtons } from './FeedbackButtons';

const ProductImage = ({ imageUrl, altText }) => (
    <div style={{ width: '50px', height: '50px', overflow: 'hidden', borderRadius: '50%' }}>
        <img src={imageUrl} alt={altText} style={{ width: '100%', height: '100%' }} />
    </div>
);

const UnitPriceList = ({ units, price }) => (
    <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
        {units.map((unit, index) => (
            <li key={index} style={{paddingLeft: '10px'}}>
                {calculateUnitPrice(price, unit.quantity, unit.unit)}
            </li>
        ))}
    </ul>
);

export const ProductOptionsList = ({ recipeIngredient, selectedIngredient, selectedProduct, productOptions, handleProductSelection }) => (
    <ul style={{ listStyleType: 'none', padding: '0' }}>
        {productOptions.map(option => {
            const isSelected = selectedProduct && selectedProduct.id === option.id;
            const productUnit = getUnitType(recipeIngredient.normalized_unit, option.units)
            const quantityNeeded = getQuantityNeeded(recipeIngredient.normalized_quantity, productUnit.quantity)
            const priceMultiplier = quantityNeeded ? quantityNeeded : 1
            const amountLeftOver = getAmountLeftOver(recipeIngredient, productUnit, quantityNeeded)
            const leftOverPercentage = getLeftOverPercentage(amountLeftOver, productUnit, quantityNeeded);
            const buttonText = isSelected ? 'Selected' : 'Select';
            const buttonClass = isSelected ? styles.disabledButton : '';

            return (
            <li key={option.id} className={isSelected ? styles.selectedProduct : ''} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '5px' }}>
                <button
                    onClick={() => !isSelected && handleProductSelection(option)}
                    className={buttonClass}
                    style={{ padding: '5px 10px', border: 'none', borderRadius: '5px', background: '#f7f7f7', cursor: 'pointer' }}
                    disabled={isSelected}
                >
                    {buttonText}
                </button>
                <ProductImage imageUrl={option.image_url} altText={option.title} />
                <div style={{ flexGrow: 1 }}>
                    <span style={{ fontWeight: 'bold', paddingRight: '5px'}}>{quantityNeeded}</span>
                    <span>{option.title}</span>
                    {option.price && ` ($${(Math.round(option.price * priceMultiplier * 100) / 100).toFixed(2)})`}
                    {leftOverPercentage !== null && (
                        <span>, {leftOverPercentage}% left over</span>
                    )}
                    <UnitPriceList units={option.units} price={option.price} />
                </div>
                <FeedbackButtons selectedIngredient={selectedIngredient} productId={option.id} />
            </li>
        )})}
    </ul>
);