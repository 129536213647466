import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(undefined);

    const setToken = (token) => {
        setAuthToken(token);
        localStorage.setItem('authToken', token);
    };

    return (
        <AuthContext.Provider value={{ authToken, setToken }}>
        {children}
        </AuthContext.Provider>
    );
};
