import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Header from './Header';
import RecipeSearch from './RecipeSearch';
import RecipeView from './RecipeView';
import MealPlan from './MealPlan';
import OAuthCallback from './components/OAuthCallback';
import { AuthProvider } from './AuthContext';
import { Provider } from 'react-redux';
import store from './state/store.ts';

const App = () => (
  <Provider store={store}>
    <Router>
      <div style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        height: '100vh',
      }}>
        <AuthProvider>
        <Header />
        <Routes>
          <Route path="/" element={<RecipeSearch />} />
          <Route path="/recipe-view/:recipeId" element={<RecipeView />} />
          <Route path="/meal-plan" element={<MealPlan />} />
          <Route path="/kroger_auth" element={<OAuthCallback />} />
        </Routes>
        </AuthProvider>
      </div>
    </Router>
  </Provider>
);

export default App;