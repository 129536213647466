import React from 'react';
import { useSelector } from 'react-redux';
import RecipePriceDisplay from './RecipePriceDisplay.js';
import { RootState } from '../state/store.js';
import { Recipe } from '../state/mealPlanSlice.js';

const RecipeCheckout: React.FC = () => {
  const selectedRecipes = useSelector<RootState, Recipe[]>(state => state.mealPlan.selectedRecipes);

  return (
    <div>
      {selectedRecipes.map((recipe, index) => (
        <RecipePriceDisplay key={index} recipe={recipe} />
      ))}
    </div>
  );
};

export default RecipeCheckout;