import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../config';
import { useAuth } from '../AuthContext';

let requestMade = false;

const OAuthCallback = () => {
  const navigate = useNavigate();
  const { setToken } = useAuth();

  useEffect(() => {
    
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = decodeURIComponent(urlParams.get('state'));

    const getAccessToken = async () => {
      if (requestMade) return;
      requestMade = true;

      const response = await axios.get(`${API_URL}/get_access_token`, { 
          params: { 
              code 
          } 
      });
      setToken(response.data.access_token);
      navigate(state || '/');
    };

    if (code) {
      getAccessToken();
    }
  }, [navigate, setToken]);

  return null;
};

export default OAuthCallback;